import Carousel from './carousel/Carousel';
import Pagination from './pagination/Pagination';
import ParallaxImage, { ParallaxImageStatus } from './parallaximage/ParallaxImage';
import { getInputRangeFromIndexes } from './utils/animations';

export {
    Carousel as default,
    Carousel,
    Pagination,
    ParallaxImage,
    ParallaxImageStatus,
    getInputRangeFromIndexes
};
